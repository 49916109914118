import {UseFormSetError} from "react-hook-form"
import {Permission, Resource, Role} from "../interfaces/authInterfaces"

export const setErrorMessageToField = (setError: UseFormSetError<any>, name: string, message: string) => {
  setError(name, {
    type: "manual",
    message: message,
  })
}

export const convertArrayToSelectOptions = (
  listItems: any[],
  labelKey: string,
  valueKey: string,
  eraseExistingKeys = true,
): never[] => {
  const arrayAdoptedForSelectOptions: any[] = []

  listItems.forEach((item, index) => {
    if (eraseExistingKeys) {
      arrayAdoptedForSelectOptions.push({value: item[valueKey], label: item[labelKey]})
    } else {
      arrayAdoptedForSelectOptions.push({...item, value: item[valueKey], label: item[labelKey]})
    }
  })
  return arrayAdoptedForSelectOptions as never
}

export const getListOfAvailablePermissions = (modules: Role[]): Permission[] => {
  let resources: Permission[] = []
  modules.forEach((module, index) => {
    module.permissions.forEach((permission) => {
      if (permission.operation?.operation_name === "read") resources.push(permission)
    })
  })
  return resources
}

export const getUniqueListOfPermissions = (roles: Role[]): Permission[] => {
  let permissions: any = []

  roles.forEach((role) => role.permissions.forEach((permission) => permissions.push(permission)))
  permissions = new Set(permissions)
  return [...permissions]
}

export const getUniqueListOfResources = (permissions: Permission[]): Resource[] => {
  let resources: any = []

  permissions.forEach((permission) => resources.push(permission.resource))
  resources = new Set(resources)
  return [...resources]
}

export const getUniqueListOfMenuItems = (resourcesGroupedByParentName: any[]) => {
  for (const parentModuleName in resourcesGroupedByParentName) {
    resourcesGroupedByParentName[parentModuleName] = [
      ...new Map(
        resourcesGroupedByParentName[parentModuleName].map((item: any) => [item.resource_name, item]),
      ).values(),
    ]
  }

  return resourcesGroupedByParentName
}

export const filterPermissionsByOperationName = (permissionList: Permission[], operationName: string): Permission[] => {
  return permissionList.filter((permission) => permission.operation?.operation_name === operationName)
}

export const groupByObjectByObjectKey = (arr: any[], groupByKey: string) => {
  return arr.reduce(function (rv, x) {
    ;(rv[x[groupByKey]] = rv[x[groupByKey]] || []).push(x)
    return rv
  }, {})
}

export const getTranslatedMessageByErrorCode = (statusCode: number) => {
  switch (statusCode) {
    case 1001:
      return "Неверное имя пользователя или пароль"
    case 1002:
      return "Введенный код неверный"
    case 1003:
      return "Пользователь заблокирован"
    case 1004:
      return "Неверный номер телефона"
    case 1005:
      return "Ваша сессия истекла. Нужно авторизоваться заново"
    case 1006:
      return "У вас не хватает прав для данного действия"
    case 1008:
      return "Поле не должно быть пустым"
    case 2001:
      return "Произошла внутренняя ошибка на сервере"
    case 2002:
      return "Неподдерживаемое содержимое запроса"
    case 2003:
      return "Тело запроса слишком большое"
    case 2004:
      return "Формат файла не поддерживаемый сервером"
    case 2005:
      return "Картинки обязательны к прикреплению"
    case 5004:
      return "Прозошла ошибка базы данных"
    case 5005:
      return "Данная сущность уже существует"
    case 5006:
      return "Искомый объект не найден в базе данных либо уже удален"
    case 5007:
      return "Удаление невозможно, так как данная сущность используется в другом месте!"
    default:
      return "Произошла неизвестная ошибка"
  }
}

export const convertFileIntoBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    let result: string | null = ""
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      result = reader.result && reader.result.toString().replace("data", "").replace(/^.+,/, "")
      resolve(result || "")
    }
  })
}

export function base64ToBlob(base64: string, mimeType: string): Blob {
  const byteCharacters = atob(base64)
  const byteArrays: Uint8Array[] = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    byteArrays.push(new Uint8Array(byteNumbers))
  }

  return new Blob(byteArrays, {type: mimeType})
}

export const reorderDragAndDropItems = (
  arr: any[],
  startImageOrder: number,
  endImageOrder: number,
  priorityKey: string = "priority_order",
) => {
  const result = [...arr]
  result[startImageOrder][priorityKey] = endImageOrder
  if (startImageOrder > endImageOrder) {
    for (let i = endImageOrder; i < result.length; i++) {
      if (i !== startImageOrder) {
        result[i][priorityKey]++
      }
    }
  } else {
    for (let i = endImageOrder; i >= 0; i--) {
      if (i !== startImageOrder) {
        result[i][priorityKey]--
      }
    }
  }
  return result.sort((first, second) =>
    first[priorityKey] > second[priorityKey] ? 1 : first[priorityKey] < second[priorityKey] ? -1 : 0,
  )
}

// Эта функция нужна для того чтобы в зависимости от переданного файла подготовить данные к отправке на сервер:
// к примеру отправить icon_path как null если файл должен быть удален
export const getPreparedRequestBodyWithFileUpload = (
  submitData: any,
  initialFileName: string | undefined,
  fileObjectKey = "file",
  fileNameObjectKey = "file_name",
  fileUrlObjectKey = "file_url",
) => {
  // Если нет файла либо нужно его удалить, то отправляет file как undefined
  if (submitData[fileNameObjectKey] === "") {
    return {...submitData, [fileUrlObjectKey]: null, [fileObjectKey]: undefined, [fileNameObjectKey]: undefined}
  }
  // Если картинку трогать не нужно, то отправляем его путь к файлу каким он был
  else if (submitData[fileNameObjectKey] === initialFileName) {
    return {...submitData, [fileUrlObjectKey]: submitData[fileUrlObjectKey]}
  }
  // Если нужно перезаписать файл, то отправляем file и file_name, а вот путь к файлу отправляем как undefined
  else {
    return {...submitData, [fileUrlObjectKey]: undefined}
  }
}

export const convertNumberInPriceFormat = (initialNumber: string | number) => {
  if (!initialNumber) return initialNumber.toString()
  return initialNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export const convertObjectValuesToString = (initialObject: any) => {
  Object.keys(initialObject).forEach((item) => {
    if (typeof initialObject[item] === "object") {
      return initialObject[item].toString()
    }

    initialObject[item] = "" + initialObject[item]
  })
  return initialObject
}

export const addHoursToDate = (date: Date, hours: number) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000)

  return date
}
export const removeHoursToDate = (date: Date, hours: number) => {
  date.setTime(date.getTime() - hours * 60 * 60 * 1000)

  return date
}

export type DateTimeApiFormat = "2022-09-30 11:53:22" | "2022-09-30"

export const convertToApiFormat = (date: Date, isLessDate: boolean = false): DateTimeApiFormat => {
  const year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let dateNum = date.getDate().toString()
  let hours = date.getHours().toString()
  let minutes = date.getMinutes().toString()
  let seconds = date.getSeconds().toString()

  if (month.length < 2) month = `0${month}`
  if (dateNum.length < 2) dateNum = `0${dateNum}`
  if (hours.length < 2) hours = `0${hours}`
  if (minutes.length < 2) minutes = `0${minutes}`
  if (seconds.length < 2) seconds = `0${seconds}`

  if (isLessDate) {
    return `${year}-${month}-${dateNum}` as DateTimeApiFormat
  }

  return `${year}-${month}-${dateNum} ${hours}:${minutes}:${seconds}` as DateTimeApiFormat
}

export const convertDateToCurrentISOFormat = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset() * 60000 //offset in milliseconds
  const fiveHoursInMilliseconds = 5 * 60 * 60 * 1000 // 5 hours in milliseconds
  const localISOTime = new Date(date.getTime() - timezoneOffset + fiveHoursInMilliseconds).toISOString().slice(0, -1)
  return localISOTime
}

export const getMonthNameByNumber = (monthNumber: number) => {
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ]
  return months[monthNumber]
}

/**
 * Функцию склонения числительных на русском языке ['товар', 'товара', 'товаров']
 * Пример: declension(priceReview.products.products_count, ["товар", "товара", "товаров"]
 */
export function declension(number: number, words: [string, string, string]): string {
  let word: string
  const cases = [2, 0, 1, 1, 1, 2]

  if (number % 100 > 4 && number % 100 < 20) {
    word = words[2]
  } else {
    word = words[cases[Math.min(number % 10, 5)]]
  }

  return `${number} ${word}`
}
