import {Channel, City, Warehouse} from "./relationsInterfaces"
import {FileUploadInterface} from "./layoutInterfaces"
import {User} from "./authInterfaces"

export interface ProductCategoryCreate extends Partial<FileUploadInterface> {
  readonly category_id: number
  category_name: string
  category_slug: string
  category_parent_id?: number
  category_children?: any[]
  category_order: number
  icon_path?: string | null
}

export interface ProductCategoryChild {
  readonly child_id: number
  child_name: string
  readonly parent_id: number | null
  parent_name: string | null
}

export interface ProductCategoryOrderChange {
  category_name: string
  category_slug: string
  category_order: number
}

export interface ProductAttribute {
  readonly attribute_id?: number
  attribute_key_id?: number
  attribute_value: string
  attribute_value_kz?: string
  attribute_value_ru?: string
  attribute_value_en?: string
}

export interface ProductAttributeKey extends Partial<FileUploadInterface> {
  readonly attribute_key_id: number
  attribute_key_name: string
  attribute_id?: number
  attribute_group_id?: number
  show_on_main?: boolean
  show_in_filters?: boolean
  unit_of_measurement?: string
  attributes?: ProductAttribute[]
  is_required?: boolean
  has_picture?: boolean | undefined
  icon_path?: string | null
}

export interface ProductAttributeGroup {
  readonly attribute_group_id: number
  attribute_group_name: string
}

export interface ProductOption {
  readonly option_id?: number
  option_key_id?: number
  option_value: string
}

export interface ProductOptionKey {
  readonly option_key_id: number
  option_key_name: string
  unit_of_measurement: string
  options?: ProductOption[]
}

export interface ProductTemplateCreate {
  readonly template_id: number
  template_name: string
  category_id: number
  option_key_id?: number
  category?: ProductCategoryCreate
  option_key?: ProductOptionKey | null
  attribute_keys?: ProductAttributeKey[]
}

export interface ProductColorCreate {
  readonly color_id: number
  color_name: string
  rgb_code: string
}

// TODO Переделать интерфейс во всех импортах
export interface ProductMainInformationCreate {
  readonly model_stock_id?: number
  product_id?: number
  model_stock_name: string
  model_stock_sku: string
  category_id?: number
  color_id: number
  brand_id?: number
  model_id: number
  option_id: number
  attributes?: number[] | null
  model?: ProductModel
  color?: ProductColorCreate
  brand?: ProductBrand
  option?: {
    readonly option_key_id: number
    option_id: number
    option_value: string | number
  }
  is_published?: boolean
  is_online_published?: boolean
  installment_percent_id: number
  content_link: string
  content_preview_link: string
  template_id: number
  pre_order?: boolean
}

export interface ProductMainInformationUpdate extends Omit<ProductMainInformationCreate, "attributes"> {
  attributes: AttachedAttributesUpdate[]
}

export interface AttachedAttributesUpdate {
  attribute_key: {
    attribute_key_id: number
    attribute_key_name: string
  }
  attributes: {
    attribute_id: number
    attribute_value: string
    attribute_slug: string
  }[]
}

export interface ProductTemplateAttributeKeyCreate {
  readonly template_id: number
  attribute_key_id: number | null | {value: number; label: string}
  is_required: boolean
}

export interface ProductTemplateOptionKeyCreate {
  template_id: number
  option_key_id: number
}

export interface Store {
  readonly store_id: number
  store_address: string
  schedule: Array<StoreWeekSchedule>
  schedule_marketplace: Array<StoreWeekSchedule>
  store_name: string
  store_latitude: string
  store_longitude: string
  is_active: boolean
  is_online_published: boolean
}

export interface StoreWeekSchedule {
  [dayName: string]: StoreScheduleDay
}

export interface StoreScheduleDay {
  start: string
  end: string
}

export interface Order {
  readonly order_id: number
  order_status_id: number
  buyer_id: number
  channel_id: number
  supplier_id: number
  shipment_user_id: number
  comment_token: string | null
  order_session: string
  ip_address: string
  created_at: string
  updated_at: string
  order_status_code: string
  order_status_name: string
  phone: string
  iin: string
  full_name: string | null
}

export interface OrderCustomerInfo {
  readonly customer_id: number
  additional_income: number
  email: string
  full_name: string
  iin: string
  income: number
  phone: string
  created_at: string
  updated_at: string
  deleted_at: string | null
}

export interface OrderDeliveryInfo {
  comment: string
  created_at: string
  delivered_at: string
  delivery_status_id: number
  delivery_type_id: number
  delivery_type: OrderDeliveryType
  entrance: string | null
  flat: string | null
  floor: string | null
  full_address: string | null
  intercom: string | null
  latitude: string | null
  longitude: string | null
  order_delivery_id: number
  order_id: number
  street_and_house: number
  updated_at: string
  warehouse: Warehouse | undefined
  warehouse_id: number
}

export interface OrderDeliveryType {
  created_at: string
  delivery_type_code: string
  delivery_type_id: number
  delivery_type_name: string
  updated_at: string
}

export interface OrderDetail {
  readonly order_id: number
  comment_token: string | null
  ip_address: string
  buyer_id: number | null
  city_id: number | null
  channel_id: number | null
  supplier_id: number | null
  shipment_user_id: number | null
  order_status_id: number
  order_session: string
  order_comment: string | null
  created_at: string
  updated_at: string
  order_services: []
  order_payments: []
  order_type: {
    order_type_code: string
  }
  order_products: OrderProduct[]
  skip_apple_validation: boolean
  skip_validation_scoring: boolean
}

export interface RootOrderService {
  created_at: string
  order_id: number
  order_product_id: number
  order_service_id: number
  order_service: {
    service: Service
    price: number
  }
  refund_order_id: number
  refund_order_service_id: number
  refund_order_service_status: number
  service_id: number
  service_type_id: number
  updated_at: string
}

export interface OrderProduct {
  readonly order_id: number
  readonly order_product_id: number
  model_stock_id: number
  price: number
  discount: number
  quantity: number
  sku: string
  warehouse_id: number | null
  model_stock: OrderModelStock
  order_services: OrderService[]
  reservation_warehouse: number
}

export enum ReservationWarehouses {
  "В городе в складе main",
  "Витрина в городе в складе showcase",
  "Мерчант virtual",
  "Доставка с других городов intercity_main",
  "Доставка с витрины других городов showcase_delivery",
  "Без перемещении",
}

export interface OrderModelStock {
  readonly model_stock_id: number
  model_stock_name: string
  model_stock_sku: string
  model_stock_slug: string
  model_id: number
  image: ProductImage
  images: ProductImage[]
  model: ProductModel
}

export interface OrderShipmentInfo {
  order_id: number
  collecting_warehouse: Pick<
    Warehouse,
    "warehouse_address" | "warehouse_name" | "warehouse_latitude" | "warehouse_longitude" | "warehouse_phone"
  > & {
    city: OrderShipmentCity
    products: OrderShipmentProduct[]
    services: OrderShipmentService[]
    warehouse_type_new: number
  }
  shipping_warehouses: (Pick<
    Warehouse,
    "warehouse_address" | "warehouse_name" | "warehouse_latitude" | "warehouse_longitude" | "warehouse_phone"
  > & {
    city: OrderShipmentCity
    products: OrderShipmentProduct[]
    services: OrderShipmentService[]
    warehouse_type_new: number
  })[]
}

export type OrderShipmentProduct = {
  discount: number
  model_stock: {
    model_stock_name: string
    model_stock_slug: string
    model_stock_sku: string
  }
  model_stock_id: number
  order_product_id: number
  price: number
  quantity: number
}

export interface OrderShipmentService {}

export type OrderShipmentCity = Pick<
  City,
  "city_latitude" | "city_longitude" | "city_name_ru" | "city_name_kk" | "city_name_en"
>

export interface OrderShipmentSupplierInfo {
  shipment:
    | {
        email: string
        full_name: string
        phone: string
        user_id: number
        warehouse_code_ut: number
      }
    | undefined
  supplier:
    | {
        email: string
        full_name: string
        phone: string
        user_id: number
        warehouse_code_ut: number
      }
    | undefined
}

export interface OrderService {
  created_at: string
  deleted_at: string | null
  device_series: number | null
  insurance_code: string
  life_insurance_percent: number | null
  migrate: string | null
  migrate_session: string | null
  readonly order_id: number
  readonly order_product_id: number
  readonly order_service_id: number
  readonly order_service_parent_id: number
  readonly order_service_status: number
  phone: string | null
  price: number
  quantity: number
  service: Service
  service_id: number
  service_type: ServiceType
  service_type_id: number
  sku: string
  tele_2_subscriber_id: number
  keeptalk_used_url: string[] | null
  warranty_url: string[] | null
  keeptalk_url: string[] | null
}

export interface OrderPaymentInfo {
  contract_percent: number
  installment: number
  life_insurance: number
  order_payments: OrderPayment[]
  period: number | null
  price_all_products: number
  price_all_services: number
  total_amount: number
  bank_name: string
}

export interface OrderPayment {
  amount: number
  broker_accumulative_scoring_id: number
  broker_credit_contract: number
  broker_loan_id: number
  broker_period: number
  created_at: string
  enable: boolean
  order_id: number
  order_payment_id: number
  order_payment_status: number
  pay_at: string
  paybox_data: null
  paybox_widget: string
  payment_type_code: string
  payment_type_name: string
  updated_at: string
}

export interface OrderStatus {
  readonly order_status_id: number
  order_status_name: string
  order_status_code: string
  order_status_color: string | null
}

export interface OrderHistory {
  readonly order_history_id: number
  order_history_description: string | null
  order_status_code: string
  order_status_name: string
  order_status_color: string
  created_at: string
  user: User | null
}

export interface OrderAdditionalInfo {
  city: City
  channel: Channel
  order_id: number
  sms_messages: OrderSmsMessages[]
  order_status: OrderHistory | undefined
  broker_credit_contract: number
  one_c_order: number
  customer_iin: string
  insurance_code: string | null
  order_session: string
  order_comment: string | null
  utm_code: string | null
  click_id: string | null
}

export interface OrderSmsMessages {
  otp: string
  text: string
}

export interface ProductModel {
  readonly model_id: number
  model_name: string
  model_name_kz: string
  model_name_ru: string
  model_name_en: string
  model_desc: string | null
  model_desc_kz: string
  model_desc_ru: string
  model_desc_en: string
  attributes: number[] | any
  category_id: number
  template_id: number | null
  brand_id: number
  brand?: ProductBrand
  category?: ProductCategoryCreate
  model_type: number
}

export interface ProductBrand {
  readonly brand_id: number
  brand_name: string
  file_name?: string
  file?: string
  icon_path?: string | null
  categories?: ProductCategoryCreate[]
}

export interface ProductWarehouseRelation {
  model_stock_id: number
  channel_id: number
  city_id: number
  warehouse_id: number
}

export interface ProductCityRelation {
  model_stock_id: number
  channel_id: number
  city_id: number
}

export interface ProductChannelRelation {
  model_stock_id: number
  channel_id: number
}

export interface ProductImage {
  readonly image_id?: number
  image_order: number
  file_name: string
  file: string
  model_stock_id: number
  image_path?: string
}

export interface SimilarProduct {
  from_model_stock_id: number
  to_model_stock_ids: number[]
}

export interface SimilarProductItem {
  is_published: boolean
  model_stock_id: number
  model_stock_name: string
  model_stock_sku: string
  model_stock_slug: string
  image_path: string
}

export interface Stock {
  readonly stock_id: number
  sku: string
  quantity: number
  price: number
  good_name: string
  warehouse?: Warehouse
  city: City
}

export interface WarehouseRelation {
  city_id: number
  channel_id: number
  warehouse_id: number
}

export interface WarehouseCityRelation {
  channel_id: number
  city_id: number
}

export interface WarehouseChannelRelation {
  channel_id: number
}

export interface UserWarehouseRelation {
  user_id: number
  city_id: number
  channel_id: number
  warehouse_id: number
}

export interface UserWarehouseCityRelation {
  user_id: number
  channel_id: number
  city_id: number
}

export interface UserWarehouseChannelRelation {
  user_id: number
  channel_id: number
}

export interface ServiceType {
  readonly service_type_id: number
  service_type: string
  service_type_code: string
  service_type_link: string
  service_type_slug: string
  approved_to_public: boolean
  approved_to_online: boolean
  created_at: Date
}

export interface Service extends FileUploadInterface, ServiceCostPrice {
  readonly service_id: number
  one_c_service_name: string
  one_c_telecom: string
  price: number
  approved_to_public: number
  description: string
  service_name: string
  service_type_id: number
  service_group_id: number
  service_link: string
  created_at: Date
  service_type?: ServiceType
  file_url: string
  content: string
  telecom_tariff?: TelecomOperator[]
  sku: string
}

export interface ServiceCostPrice {
  cost_price: number | null
  cost_price_type: string | null
}

export interface ServiceGroup {
  readonly service_group_id: number
  service_group_name: string
  content: string | null
  description: string | null
  additional_data: string | null
}

export interface UserReport {
  count_all: number
  count_active: number
  count_blocked: number
}

export interface WarehouseReport {
  count_all: number
  count_main: number
  count_others: number
}

export interface CityReport {
  readonly city_id: number
  city_name_en: string
  city_name_ru: string
  count_all: number
  count_main: number
  count_other: number
  count_model_stocks: number
}

export interface ChannelReport {
  readonly channel_id: number
  name: string
  count_users: number
  count_warehouses: number
  count_cities: number
  count_model_stocks: number
}

export interface CategoryBrand {
  readonly brand_id: number
  category_ids: number[]
}

export interface InstallmentPercent {
  readonly instalment_percent_id: number
  percent: number
}

export interface TelecomOperator extends Partial<FileUploadInterface> {
  readonly telecom_operator_id: number
  telecom_operator_name: string
  telecom_operator_code: string
  is_publish: boolean
  logo_url: string
  logo_name: string
}

export interface CardType {
  readonly card_type_id: number
  enable: boolean
  card_type_code: string
  card_type_name: string
  file_name: string
  file: string
  icon_path: string
}

export interface PublicOffer {
  public_offer_id: number
  date_to: string
  public_offer_link: string
  file_path: string
  date_from: string
  created_at: string
  fdepositor_id: string
  file_name: string
}
export interface PublicOfferList {
  total: number
  page: number
  size: number
  items: PublicOffer[] | null
}

export interface IOrderAction {
  id: string
  event_id: string
  user_id: number
  full_name: string
  phone: string
  event_message: string
  created_at: string
  params: {
    after: string
    before: string
    comment: string
  }
}
